import React from "react";
import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";

const defaultProps = {
  defaultValue: "",
  required: false,
};

function DateInput(props) {
  return (
    <div className="form-element">
      <label htmlFor={props.name} className="form-input-label">
        {props.label}{" "}
        {props.required ? <abbr title="Obrigatório">*</abbr> : null}
      </label>

      <Flatpickr
        className="form-input cursor-pointer"
        id={props.name}
        name={props.name}
        options={{
          dateFormat: "Y-m-d",
          altInput: true,
          altFormat: "d/m/Y",
          locale: Portuguese,
        }}
        defaultValue={props.defaultValue}
        required={props.required}
      />
    </div>
  );
}

DateInput.defaultProps = defaultProps;
DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
};

export default DateInput;
